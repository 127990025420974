import React from 'react';

import { Global } from '@emotion/react';

import * as styles from './Layout.style';


interface Props {
  children: React.ReactNode;
}

const Layout = ({
  children,
}: Props) => {
  return (
    <>
      <Global styles={[styles.reset]} />
      {children}
    </>
  );
};

export default Layout;
