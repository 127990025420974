import MailIcon from 'assets/images/ic_mail.module.svg';

import * as styles from './BuildOnPylon.style';


const BuildOnPylon = () => (
  <section css={styles.container}>
    <div css={styles.headingContainer}>
      <p css={styles.fakeHeading}>
        Pylon enables
      </p>
      <h2 css={styles.heading}>
        Build on <br />
        Pylon
      </h2>
    </div>
    <div css={styles.contentContainer}>
      <p css={styles.description}>
        Any service provider platforms that bridge users and creators will be able to easily integrate Pylon's software, widget, and SDK to accept payments via yield. Pylon has forthcoming applications in service payments, philanthropy, patronage, investments, rentals, and savings.
      </p>
      <a
        css={styles.letsTalk}
        href='mailto:contact@pylon.money'
      >
        <MailIcon css={styles.mailIcon} />
        <p>
          <span>
            Let’s talk
          </span>
          <span css={styles.letsTalkDescription}>
            {' — build the next generation of payments with us.'}
          </span>
        </p>
      </a>
    </div>
  </section>
);

export default BuildOnPylon;
