import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import OpenGraphImage from 'assets/open-graph/image.png';


interface Props {
  title?: string;
  description?: string;
  image?: string;
  lang?: string;
  meta?: {
    [key: string]: string;
  }[];
}

type Query = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      siteUrl: string;
    };
  };
};


const SEO = ({
  title = '',
  description = '',
  lang = 'en',
  meta = [],
}: Props) => {
  const { site } = useStaticQuery<Query>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const defaultOGImage = `${site.siteMetadata?.siteUrl}${OpenGraphImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || defaultTitle}
      titleTemplate={
        title && defaultTitle
          ? `%s | ${defaultTitle}`
          : undefined
      }
      meta={[
        {
          charset: 'utf-8',
        },
        {
          httpEquiv: 'x-ua-compatible',
          content: 'ie=edge',
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:site_name',
          content: title,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:image',
          content: defaultOGImage,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author ?? '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...meta,
      ]}
    >
      {/* Web Font */}
      <link rel="stylesheet" href="https://use.typekit.net/sfp4mwu.css" />
    </Helmet>
  );
};

export default SEO;
