import { css } from '@emotion/react';


export const container = css`
  padding: 128px 240px;
  background: #0852f6;

  @media all and (max-width: 1200px) {
    padding: 100px 10%;
  }

  @media all and (max-width: 640px) {
    padding: 40px 6% 80px;
  }
`;

export const titleContainer = css`
  margin-bottom: 40px;

  @media all and (max-width: 640px) {
    margin-bottom: 32px;
  }
`;

export const title = css`
  font-size: 48px;
  line-height: 56px;
  color: white;

  @media all and (max-width: 640px) {
    font-size: 28px;
    line-height: 30px;
  }
`;

export const titleHighlight = css`
  color: black;
  opacity: 0.4;
`;

export const subscriptionContainer = css`
  margin-bottom: 54px;
`;

export const formContainer = css`
  margin-bottom: 16px;
`;

export const inputWrapper = css`
  display: flex;
  align-items: center;

  width: 560px;

  color: white;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;

  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(14px);
  border-radius: 4px;

  transition: background 0.25s ease;

  &:focus-within {
    background: rgba(255, 255, 255, ${0.66 * 0.33});
  }

  @media all and (max-width: 640px) {
    display: block;
    width: 100%;
  }
`;

export const input = css`
  flex-grow: 1;
  height: 56px;
  padding: 0 24px;
  padding-right: 0;

  color: inherit;
  font: inherit;

  background: none;
  border: none;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.66);
  }

  @media all and (max-width: 640px) {
    width: 100%;
    height: 42px;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

export const submitButton = css`
  flex-shrink: 0;
  height: 56px;
  padding: 0 24px;

  color: inherit;
  font: inherit;

  @media all and (max-width: 640px) {
    width: 100%;
    height: 42px;
    font-size: 16px;
  }
`;

export const subscriptionDescription = css`
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.75);
`;

export const snsList = css`
  svg {
    width: auto;
    height: 20px;
  }

  li {
    display: inline-block;
    margin-right: 32px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media all and (max-width: 640px) {
    svg {
      height: 18px;
    }

    li {
      margin-right: 24px;
    }
  }
`;
