import React, { useCallback, useRef } from 'react';

import addToMailchimp from 'gatsby-plugin-mailchimp';

import { ExternalLink } from 'components';

import TwitterLogo from 'assets/images/ic_sns_twitter.module.svg';
import TelegramLogo from 'assets/images/ic_sns_telegram.module.svg';
import MediumLogo from 'assets/images/ic_sns_medium.module.svg';

import * as styles from './Footer.style';


const SNS_LIST = [
  { alt: 'Twitter', to: 'https://twitter.com/pylon_protocol', Logo: TwitterLogo },
  { alt: 'Telegram', to: 'https://t.co/QZUvCj2mqD?amp=1', Logo: TelegramLogo },
  { alt: 'Medium', to: 'https://medium.com/terra-money/pylon-protocol-e2a622ac553e', Logo: MediumLogo },
];


const Footer = () => {
  const inputRef = useRef<HTMLInputElement>(null);


  const handleSubmitForm = useCallback(async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    if (!inputRef.current) {
      return false;
    }

    const { value: email } = inputRef.current;
    const response = await addToMailchimp(email);

    const [message = ''] = response.msg.split('<a');
    window.alert(message);

    if (response.result === 'success') {
      inputRef.current.value = '';
    }

    return false;
  }, []);


  return (
    <footer css={styles.container}>
      <div css={styles.titleContainer}>
        <h3 css={styles.title}>
          <span>
            We’re building the next generation of payments.
          </span>
          <br />
          <span css={styles.titleHighlight}>
            Stay in touch with us.
          </span>
        </h3>
      </div>
      <div css={styles.subscriptionContainer}>
        <form
          css={styles.formContainer}
          onSubmit={handleSubmitForm}
        >
          <div css={styles.inputWrapper}>
            <input
              css={styles.input}
              name='email'
              type='email'
              placeholder='Enter your email'
            />
            <button
              css={styles.submitButton}
              type='submit'
            >
              Get Updates
            </button>
          </div>
        </form>
        <p css={styles.subscriptionDescription}>
          Receive launch updates, exclusive offerings, and the latest news.
        </p>
      </div>
      <ul css={styles.snsList}>
        {SNS_LIST.map(({ alt, to, Logo }) => (
          <li key={alt}>
            <ExternalLink
              to={to}
              title={alt}
            >
              <Logo />
            </ExternalLink>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
