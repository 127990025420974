import { ExternalLink } from 'components';

import MoreIcon from 'assets/images/ic_link_more.module.svg';
import ServiceStructureIllust from 'assets/images/illust_service_diagram.png';

import * as styles from './Introduction.style';


const Introduction = () => (
  <section css={styles.container}>
    <div css={styles.wrapper}>
      <h2 css={styles.heading}>
        <div>
          Pylon enables
        </div>
        <div css={styles.headingHighlight}>
          sustainable transactions between
        </div>
        <div>
          users and creators.
        </div>
      </h2>
      <p css={styles.description}>
        Pylon aligns the interests of both users and creators involved in long-term value exchanges via simple user deposits and stable yield payouts over time. Pylon's products build on low-volatile yield-bearing protocols in order to unlock a new suite of options for payments and savings.
      </p>
      <ExternalLink
        css={styles.learnMore}
        to='https://medium.com/terra-money/pylon-protocol-e2a622ac553e'
      >
        <span>
          Learn More
        </span>
        <MoreIcon />
      </ExternalLink>
    </div>
    <img
      css={styles.illust}
      src={ServiceStructureIllust}
    />
  </section>
);

export default Introduction;
