import { css } from '@emotion/react';


export const container = css`
  // position: fixed;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;

  width: 100%;
  padding: 24px 32px;
  z-index: 100;

  > div {
    flex-grow: 1;
    flex-shrink: 0;

    &:last-of-type {
      text-align: right;
    }
  }

  @media all and (max-width: 640px) {
    padding: 20px 6%;
  }
`;

export const badgeLogo = css`
  // max-width: 4vw;
  // min-width: 64px;
  max-width: 80vw;
  margin-top: -15%;
  filter: drop-shadow(0px -76px 214px #F8E5C0), drop-shadow(0px 50px 150px #01E0F7), drop-shadow(0px -30px 150px #CD01C6);
  user-select: none;
`;

export const logo = css`
  width: auto;
  height: 56px;

  @media all and (max-width: 640px) {
    height: 40px;
  }
`;

export const linkContainer = css`
  display: flex;
  align-items: center;
  flex-shrink: 1 !important;
  flex-grow: 0 !important;

  @media all and (max-width: 640px) {
    display: none;
  }
`;

export const snsList = css`
  display: inline-block;
  margin-right: 40px;
  svg {
    height: 40px;
  }

  li {
    display: inline-block;
    margin-right: 32px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const webAppLink = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 40px;

  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.75);

  background: #00eefa;
  border-radius: 6px;

  box-shadow: 0px 8px 40px #00eefa, 0px 32px 120px rgba(0, 0, 0, 0.05);

  span {
    margin-left: 8px;
  }

  svg {
    width: auto;
    height: 24px;

    path {
      stroke: rgba(0, 0, 0, 0.75);
      fill: rgba(0, 0, 0, 0.75);
    }
  }

  @media all and (max-width: 640px) {
    width: 150px;
    height: 34px;

    font-size: 14px;
    box-shadow: 0px 4px 20px #00eefa, 0px 16px 60px rgba(0, 0, 0, 0.05);

    span {
      margin-right: 6px;
    }

    svg {
      height: 12px;
    }
  }
`;
