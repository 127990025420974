import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/react';


const GradientContainer = styled.div`
  position: relative;
  padding-top: 35vh;
  // background: radial-gradient(114.23% 95.75% at 50% 0%, #000000 0%, #0852F6 48.96%, #01D9F7 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #C4C4C4;
  background: radial-gradient(114.23% 95.75% at 50% 0%, #000000 0%, #0852F6 48.96%, #01D9F7 100%);

  @media all and (max-width: 640px) {
    padding-top: 200px;
  }
`;

const wrapper = css`
  max-width: 1300px;
  margin: 0 auto;
`;


interface Props extends React.ComponentProps<typeof GradientContainer> {
}



const GradientWrapper = ({
  children,
  ...props
}: Props) => (
  <GradientContainer {...props}>
    <div css={wrapper}>
      {children}
    </div>
  </GradientContainer>
);

export default GradientWrapper;
