import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { ExternalLink } from 'components';

import MoreIcon from 'assets/images/ic_arrow_forward.module.svg';
import PaymentViaYieldIllust from 'assets/images/illust_principal_1.png';
import RetrievableDepositIllust from 'assets/images/illust_principal_2.png';

import InvestorIllust from 'assets/images/illust_gateway_investors.png';
import ProjectTeamIllust from 'assets/images/illust_gateway_team.png'

import TokenOneIllust from 'assets/images/illust_launchpad_1.png';
import TokenTwoIllust from 'assets/images/illust_launchpad_2.png';
import TokenThreeIllust from 'assets/images/illust_launchpad_3.png';

import * as styles from './FeatureSection.style';


const FeatureSection = () => {
  const gatewayContainerRef = useRef<HTMLDivElement>(null);
  const gatewayTitleRef = useRef<HTMLDivElement>(null);

  const [paddingBottom, setPaddingBottom] = useState(0);


  const handleResizeWindow = useCallback(() => {
    if (
      !gatewayContainerRef.current ||
      !gatewayTitleRef.current
    ) {
      return;
    }

    const {
      clientHeight: containerHeight,
    } = gatewayContainerRef.current;

    const {
      clientHeight: textHeight,
    } = gatewayTitleRef.current;

    setPaddingBottom(containerHeight - textHeight);
  }, []);


  useEffect(() => {
    handleResizeWindow();
  }, []);


  return (
    <>
      <section css={styles.container}>
        <div
          css={styles.floatingContainer}
          style={{
            // @ts-ignore
            '--padding-bottom': `${paddingBottom}px`,
          }}
        >
          <h2 css={styles.floatingTitle}>
            Pylon powers
          </h2>
        </div>
        <div>
          <article css={styles.section}>
            <span css={styles.mobileTitle}>
              Pylon powers
            </span>
            <h2 css={styles.heading}>
              principal-protected <br />
              payments.
            </h2>
            <div css={styles.principalContainer}>
              <div css={styles.principalCard}>
                <img
                  alt='Payments via yield'
                  css={styles.principalIllust}
                  src={PaymentViaYieldIllust}
                />
                <p css={styles.principalHeading}>
                  Payments via yield
                </p>
                <p>
                  Deposit your Terra stablecoins for access to perks and services powered by yield redirection.
                </p>
              </div>
              <div css={styles.principalCard}>
                <img
                  alt='Retrievable deposits'
                  css={styles.principalIllust}
                  src={RetrievableDepositIllust}
                />
                <p css={styles.principalHeading}>
                  Retrievable deposits
                </p>
                <p>
                  Withdraw and retrieve your full principal upon subscription expiry, base capital untouched.
                </p>
              </div>
            </div>
            <ExternalLink
              css={styles.learnMore}
              to='https://medium.com/terra-money/pylon-protocol-e2a622ac553e'
            >
              <span>
                Read how it works
              </span>
              <MoreIcon />
            </ExternalLink>
          </article>
          <article
            ref={gatewayContainerRef}
            css={styles.section}
          >
            <span css={styles.mobileTitle}>
              Pylon powers
            </span>
            <h2>
              <div
                ref={gatewayTitleRef}
                css={[
                  styles.heading,
                  styles.gatewayHeading,
                ]}
              >
                Gateway,
              </div>
              <div
                css={[
                  styles.heading,
                  styles.subheading,
                ]}
              >
                Terra’s token launchpad.
              </div>
            </h2>
            <div css={styles.gatewayContainer}>
              <div css={styles.gatewayCard}>
                <img
                  alt='For Investors'
                  css={styles.gatewayIllust}
                  src={InvestorIllust}
                />
                <p css={styles.gatewayTitle}>
                  For Investors
                </p>
                <p>
                  Make lossless investments while accessing all the benefits of being an early investor.
                </p>
              </div>
              <div css={styles.gatewayCard}>
                <img
                  alt='For Project Teams'
                  css={styles.gatewayIllustTeam}
                  src={ProjectTeamIllust}
                />
                <p css={styles.gatewayTitle}>
                  For Project Teams
                </p>
                <p>
                  Customize your blockchain project fundraising while receiving consistent payouts via yield.
                </p>
              </div>
            </div>
            <ExternalLink
              css={styles.learnMore}
              to='https://gateway.pylon.money'
            >
              <span>
                Enter Pylon Gateway
              </span>
              <MoreIcon />
            </ExternalLink>
          </article>
        </div>
      </section>
      <div css={styles.tokenIllustWrapper}>
        <img
          alt='token launchpad'
          css={styles.tokenOne}
          src={TokenOneIllust}
        />
        <img
          alt='token launchpad'
          css={styles.tokenTwo}
          src={TokenTwoIllust}
        />
        <img
          alt='token launchpad'
          css={styles.tokenThree}
          src={TokenThreeIllust}
        />
      </div>
    </>
  );
};

export default FeatureSection;
