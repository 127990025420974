import { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';

import { TimelineMax, TweenMax, CSSPlugin, Power4 } from 'gsap/all';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import PylonIllust from 'assets/images/illust_pylon.png';
import PylonRotation from 'assets/images/pylon-rotation.gif';
import PylonVideo from 'assets/images/pylon-rotation.webm';

import * as styles from './Hero.style';

const Hero = () => {
  const [apyLP, setAPYLP] = useState<any | null>(null);
  const [apyGateway, setAPYGateway] = useState<any | null>(null);

  const refTitle1 = useRef<any | null>(null);
  const refTitle2 = useRef<any | null>(null);
  const refDescription = useRef<any | null>(null);
  const refForm = useRef<any | null>(null);
  const refStats = useRef<any | null>(null);
  const refPylonContainer = useRef<any | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const [isBadBrowser, setIsBadBrowser] = useState<any | null>(false);

  function checkIfSafari() {
    const navigator = window.navigator;
    const ua = navigator.userAgent.toLowerCase();
    const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo);
    const isSafari =
      ua.indexOf('safari') != -1 && !(ua.indexOf('chrome') != -1) && ua.indexOf('version/') != -1;
    return isSafari && hasMediaCapabilities;
  }
  function checkIfIE() {
    return !!window.navigator.userAgent.match(/Trident\/7\./, []);
  }

  useEffect(() => {
    console.log('useEffect', refTitle1.current, refTitle2.current);
    if (refTitle1.current && refTitle2.current) {
      console.log('Ready on load', TweenMax.to);

      TweenMax.to(refTitle1.current, 0, { y: 64, opacity: 0 });
      TweenMax.to(refTitle2.current, 0, { y: 64, opacity: 0 });
      TweenMax.to(refDescription.current, 0, { y: 32, opacity: 0 });
      TweenMax.to(refForm.current, 0, { y: 32, opacity: 0 });
      TweenMax.to(refStats.current, 0, { y: 32, opacity: 0 });

      let loadSequence = new TimelineMax({ paused: true })
        .to({}, 2.5, {})
        .to(refTitle1.current, 1.6, { y: 0, opacity: 1, ease: Power4.easeInOut })
        .to(refTitle2.current, 1.6, { y: 0, opacity: 1, ease: Power4.easeInOut }, '-=1.3')
        .to(refDescription.current, 2.2, { y: 0, opacity: 1, ease: Power4.easeInOut }, '-=1.2')
        .to(refForm.current, 2.4, { y: 0, opacity: 1, ease: Power4.easeInOut }, '-=1.7')
        .to(refStats.current, 2.4, { y: 0, opacity: 1, ease: Power4.easeInOut }, '-=1.7');

      loadSequence.play();
    }

    setIsBadBrowser(checkIfSafari || checkIfIE);

    setTimeout(() => {
      if (document.getElementById('pylon-player')) {
        document.getElementById('pylon-player').play();
      }
    }, 300);

    if (false) {
      const canvas = document.getElementById('the-pylon');
      const context = canvas.getContext('2d');

      canvas.width = 1158;
      canvas.height = 770;

      if (refPylonContainer.current) {
        let boundingBox = refPylonContainer.current.getBoundingClientRect();
        canvas.width = boundingBox.width;
        canvas.height = boundingBox.height;
      }

      const frameCount = 147;
      const currentFrame = (index) =>
        `https://www.apple.com/105/media/us/airpods-pro/2019/1299e2f5_9206_4470_b28e_08307a42f19b/anim/sequence/large/01-hero-lightpass/${(
          index + 1
        )
          .toString()
          .padStart(4, '0')}.jpg`;

      const images = [];
      const airpods = {
        frame: 0,
      };

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }

      images[0].onload = render;

      function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[airpods.frame], 0, 0);

        setTimeout(() => {
          airpods.frame++;
          render();
          console.log('Animate pod', airpods.frame);
        }, 100);
      }
    }
  }, []);

  const handleSubmitForm = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!inputRef.current) {
      return false;
    }

    const { value: email } = inputRef.current;
    const response = await addToMailchimp(email);

    const [message = ''] = response.msg.split('<a');
    window.alert(message);

    if (response.result === 'success') {
      inputRef.current.value = '';
    }

    return false;
  }, []);

  return (
    <section css={styles.container}>
      <article css={styles.wrapper}>
        <div css={styles.titleContainer}>
          <div css={styles.titleWrapper}>
            <h1 css={styles.title} ref={refTitle1}>
              Deposit money.
            </h1>
            <h1 css={styles.title} ref={refTitle2}>
              Spend yields.
            </h1>
          </div>
          <p css={styles.description} ref={refDescription}>
            Make lossless investments. <br />
            Access no-fee services. <br />
            Base capital untouched.
          </p>
        </div>
        <a ref={refForm} href="https://app.pylon.money" target="_blank">
          <div css={styles.cta}>
            Go to the Web App
          </div>
        </a>
        <div css={styles.stats} ref={refStats}>
          {/* <div css={styles.statsMetric}>
            <p>MINE-UST LP</p>
            <h3>{apyLP}% APR</h3>
          </div>
          <div css={styles.statsMetric}>
            <p>Gateway</p>
            <h3>Up to {apyGateway}% APR</h3>
          </div> */}
        </div>
        {/*<form onSubmit={handleSubmitForm} >
          <div css={styles.inputWrapper}>
            <input
              ref={inputRef}
              css={styles.input}
              name="email"
              type="email"
              placeholder="Enter your email"
            />
            <button css={styles.submitButton} type="submit">
              Get Updates
            </button>
          </div>
        </form>*/}
      </article>
      <div css={styles.illustWrapper}>
        <div ref={refPylonContainer} css={styles.illustContainer}>
          {/*<canvas id="the-pylon" css={styles.thePylon} />*/}
          {isBadBrowser ? (
            <img css={styles.pylonIllust} src={PylonRotation} />
          ) : (
            <video
              id="pylon-player"
              src={PylonVideo}
              css={styles.pylonVideo}
              loop
              muted
              autoplay
              playsinline
            ></video>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
