import styled from "@emotion/styled";
import { motion } from 'framer-motion';

export default function Page() {
  return (
    <div>
      <Container>
        <Card
          initial={{ transform: 'translate3d(0, 350px, 0)' }}
          animate={{ transform: 'translate3d(0, 0px, 0)' }}
          transition={{ type: 'spring' }}
        >
          <Title>Notice</Title>
          <Description>
            In light of recent events on Terra Classic, Pylon Protocol is continuing
            to explore all possible options moving forward. We are committed to our
            vision of platforms built around yield redirection and deposit
            contracts. Please check our{' '}
            <a
              href="https://twitter.com/pylon_protocol"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>{' '}
            or{' '}
            <a href="https://t.me/pylon_protocol" target="_blank" rel="noreferrer">
              Telegram
            </a>{' '}
            for more updates and information. By "principal-protected" and
            "lossless", this means a guarantee of minimum return equal to the
            investor's amount, not value, of initial investment.
          </Description>
        </Card>
      </Container>
    </div>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Fallback background color until image load */
  background: rgba(0, 0, 0, .35);
  backdrop-filter: blur(30px);
`

const Card = styled(motion.main)`
  margin: 64px 0;
  padding: 64px 48px;
  max-width: 600px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @media all and (max-width: 640px) {
    padding: 48px 24px;
    width: 85%;
  }
  @media all and (max-width: 400px) {
    padding: 48px 20px;
    width: 90%;
  }
`

const RainbowMineWrapper = styled(motion.div)`
  width: 209.25px;
  height: 234.56px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (max-width: 640px) {
    width: 156.93px;
    height: 175.92px;
  }
`

const Title = styled.h1`
  margin-top: 48px;
  font-weight: 800;
  font-size: 48px;
  line-height: 54px;
  text-align: center;
  color: rgba(209, 227, 255, 0.95);
  @media all and (max-width: 640px) {
    margin-top: 32px;
    font-size: 2.4rem;
    line-height: 1.25;
  }
  @media all and (max-width: 400px) {
    font-size: 2.2rem;
    line-height: 1.15;
  }
`

const Description = styled.p`
  margin-top: 16px;
  font-size: 18px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.85);
  @media all and (max-width: 640px) {
    font-size: 1.2rem;
    line-height: 1.4;
  }
  @media all and (max-width: 400px) {
    font-size: 1.1rem;
    line-height: 1.25;
  }
  a {
    font-weight: normal;
    text-decoration: underline;
  }
`;

