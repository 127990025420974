import { css } from '@emotion/react';


export const container = css`
  display: flex;
  padding: 0 105px 180px;

  @media all and (max-width: 1024px) {
    display: block;
    padding: 0 10% 160px;
  }

  @media all and (max-width: 640px) {
    display: block;
    padding: 0 6% 120px;
  }
`;

export const headingContainer = css`
  padding-right: 48px;
  margin-left: -48px;
  flex-shrink: 0;

  @media all and (max-width: 1024px) {
    padding: 0;
    margin-left: 0;
    margin-bottom: 8px;
  }

  @media all and (max-width: 640px) {
    padding: 0;
    margin-left: 0;
    margin-bottom: 16px;
  }
`;

/* to make the width same with 'Pylon powers' area */
export const fakeHeading = css`
  height: 0;
  overflow-y: hidden;

  font-weight: 700;
  font-size: 72px;
  line-height: 74px;

  @media all and (max-width: 1024px) {
    font-size: 54px;
    line-height: 54px;
  }

  @media all and (max-width: 640px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const heading = css`
  font-size: 72px;
  line-height: 74px;
  text-align: right;
  color: rgba(0, 0, 0, 0.66);

  @media all and (max-width: 1024px) {
    text-align: left;
    font-size: 48px;
    line-height: 48px;

    br {
      display: none;
    }
  }

  @media all and (max-width: 640px) {
    text-align: left;
    font-size: 36px;
    line-height: 36px;

    br {
      display: none;
    }
  }
`;

export const contentContainer = css`
  padding-top: 14px;

  @media all and (max-width: 640px) {
    padding-top: 0;
  }
`;

export const description = css`
  max-width: 500px;

  margin-bottom: 24px;

  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.66);

  @media all and (max-width: 1024px) {
    padding-right: 80px;
    margin-bottom: 48px;
    max-width: none;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 26px;
  }
`;

export const letsTalk = css`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 20px;

  p {
    line-height: 1;
  }

  @media all and (max-width: 640px) {
    font-size: 16px;
  }
`;

export const mailIcon = css`
  margin-right: 12px;

  width: auto;
  height: 20px;

  @media all and (max-width: 640px) {
    margin-top: 4px;
    height: 16px;
  }
`;

export const letsTalkDescription = css`
  @media all and (max-width: 640px) {
    display: none;
  }
`;
