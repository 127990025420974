import { css } from '@emotion/react';


export const container = css`
  padding: 0 108px 60px;
  min-height: 500px;

  @media all and (max-width: 1024px) {
    padding: 0 10% 200px;

    height: auto;
    min-height: none;
  }

  @media all and (max-width: 640px) {
    padding: 0 6% 180px;

    height: auto;
    min-height: none;
  }
`;

export const wrapper = css`
  position: relative;
  z-index: 1;
`;

export const illust = css`
  margin-top: -20%;
  width: 100%;
  mix-blend-mode: luminosity;

  @media all and (max-width: 1024px) {
    margin-top: -20px;
  }

  @media all and (max-width: 640px) {
    margin-top: -10%;
  }
`;

export const heading = css`
  margin-bottom: 32px;

  font-weight: 700;
  font-size: 72px;
  line-height: 74px;
  color: white;

  @media all and (max-width: 1024px) {
    margin-bottom: 28px;
    font-size: 54px;
    line-height: 54px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 36px;
  }
`;

export const headingHighlight = css`
  display: block;
  background: linear-gradient(
    90deg,
    #4d73ff 8.13%,
    #9584f7 22.55%,
    #56b9f9 41.63%,
    #01e1f0 56.22%,
    #01b0ef 83.9%,
    #447aff 91.39%,
    #7157f9 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const description = css`
  display: block;
  max-width: 540px;
  margin-bottom: 20px;

  font-size: 20px;
  line-height: 32px;
  color: white;

  @media all and (max-width: 640px) {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const learnMore = css`
  display: inline-flex;
  align-items: center;

  height: 32px;

  font-weight: 600;
  font-size: 20px;
  color: white;

  span {
    margin-right: 12px;
    line-height: 1;
  }

  @media all and (max-width: 640px) {
    font-size: 18px;

    span {
      margin-right: 8px;
    }

    svg {
      width: auto;
      height: 18px;
    }
  }
`;
