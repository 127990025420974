import { SEO } from 'components';
import { Layout, Navigation, Footer } from 'containers/layout';

import {
  Hero,
  GradientWrapper,
  Introduction,
  FeatureSection,
  BuildOnPylon,
} from 'containers/index-page';

import Shutdown from 'containers/shutdown';


const IndexPage = () => (
  <Layout>
    <SEO />
    <Navigation />
    <Hero />
    <GradientWrapper>
      <Introduction />
      <FeatureSection />
      <BuildOnPylon />
    </GradientWrapper>
    <Footer />
    <Shutdown />
  </Layout>
);

export default IndexPage;
