import React, { useEffect, useRef } from 'react';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';

import { ExternalLink } from 'components';

import Logo from 'assets/images/ic_logo.module.svg';
import GlobeIcon from 'assets/images/ic_laptop.module.svg';
import TwitterLogo from 'assets/images/ic_sns_twitter.module.svg';
import TelegramLogo from 'assets/images/ic_sns_telegram.module.svg';
import MediumLogo from 'assets/images/ic_sns_medium.module.svg';
import PylonIcon from 'assets/images/pylon-icon.png';

import * as styles from './Navigation.style';

const SNS_LIST = [
  { alt: 'Twitter', to: 'https://twitter.com/pylon_protocol', Logo: TwitterLogo },
  { alt: 'Telegram', to: 'https://t.co/QZUvCj2mqD?amp=1', Logo: TelegramLogo },
  { alt: 'Medium', to: 'https://medium.com/terra-money/pylon-protocol-e2a622ac553e', Logo: MediumLogo },
];

const Navigation = () => {
  const refNavigation = useRef<any | null>(null);
  const refPageOverlay = useRef<any | null>(null);
  const refLogo = useRef<any | null>(null);

  useEffect(() => {
    if (refNavigation.current && refPageOverlay.current && refLogo.current) {
      console.log('Animate nav load');

      TweenMax.to(refNavigation.current, 0, { opacity: 0 });

      let loadSequence = new TimelineMax({ paused: true })
        .to(refLogo.current, 0.7, { opacity: 0.66 })
        .to(refLogo.current, 0.7, { opacity: 1 })
        .to(refLogo.current, 0.7, { opacity: 0.66 })
        .to(refPageOverlay.current, 1, { opacity: 0 })
        .to({}, 2, {})
        .to(refNavigation.current, 1, { opacity: 1 });

      loadSequence.play();
    }
  }, []);

  return (
    <>
      <div
        ref={refPageOverlay}
        className="page-overlay"
        style={{
          position: 'fixed',
          zIndex: 999999999999,
          width: '100vw',
          height: '100vh',
          background: '#000000',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#ffffff',
        }}
      >
        <div ref={refLogo}>
          <img css={styles.badgeLogo} src={PylonIcon} />
        </div>
      </div>
      <nav ref={refNavigation} css={styles.container}>
        <div>
          <Logo css={styles.logo} />
        </div>
        <div css={styles.linkContainer}>
          <ul css={styles.snsList}>
            {SNS_LIST.map(({ alt, to, Logo }) => (
              <li key={alt}>
                <ExternalLink to={to} title={alt}>
                  <Logo />
                </ExternalLink>
              </li>
            ))}
          </ul>
          <ExternalLink
            css={styles.webAppLink}
            to='https://app.pylon.money'
            title='Webapp'
          >
            <GlobeIcon />
            <span>
              Webapp
            </span>
          </ExternalLink>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
